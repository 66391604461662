var site = {
	rate : 500,
	prefix : ''
};

Object.size = function(obj) {
	var size = 0, key;
	for (key in obj) {
		if (obj.hasOwnProperty(key)){
			size++;
		}
	}
	return size;
};

(function($){

	new WOW().init();

	// var md = new MobileDetect(window.navigator.userAgent);

	var $wrapper, $slider, $preloader, $pwrapper, $pslider, $ppreloader;

	if( $('.banner').length ){
		$wrapper = $('.banner');
		$slider = $('.banner-cycle');
		$preloader = $('.banner-preloader');

		$wrapper.append('<div class="slideshow-loader"></div>');
		$wrapper.find('.slideshow-loader').hide().fadeIn();

		$preloader.imagesLoaded(function(){

			$wrapper.find('.slideshow-loader').fadeOut(500);

			$slider.cycle({
				speed : 500,
				timeout : 5000,
				swipe : true,
				slides : "> .cycle-slide",
				fx : 'fadeout',
				swipeFx :  'scrollHorz'
			});

			$slider.delay(500).fadeIn('slow',function(){
				// if( $('.home').length ){
				// 	$('.main-hero-info, .basic-hero-type').removeClass('paused');
				// }
				$('.main-hero-info, .basic-hero-type').removeClass('paused');
			});

		});

	}

	// var $wrapper, $slider, $preloader, $pwrapper, $pslider, $ppreloader;

	// if( $('.banner').length ){
	// 	$wrapper = $('.banner');
	// 	$slider = $('.carousel');
	// 	$preloader = $('.banner-preloader');

	// 	$wrapper.append('<div class="slideshow-loader"></div>');
	// 	$wrapper.find('.slideshow-loader').hide().fadeIn();

	// 	$preloader.imagesLoaded(function(){

	// 		$wrapper.find('.slideshow-loader').fadeOut(500);

	// 		$slider.flickity({
	// 			cellAlign: 'left',
	// 	  		contain: true,
	// 	  		wrapAround: true,
	// 	  		lazyLoad: true,
	// 			imagesLoaded: true
	// 		});

	// 		$slider.delay(500).fadeIn('slow',function(){
	// 			// if( $('.home').length ){
	// 			// 	$('.main-hero-info, .basic-hero-type').removeClass('paused');
	// 			// }
	// 			$('.main-hero-info, .basic-hero-type').removeClass('paused');
	// 		});

	// 	});

	// }

	

	// function initializeMap(args) {

	// 	var venue = new google.maps.LatLng(args.latitude, args.longitude);
	// 	var mapOptions = {
	// 		center: venue,
	// 		scrollwheel: false,
	// 		zoom: 16,
	// 		mapTypeId: google.maps.MapTypeId.ROADMAP,
	// 		mapTypeControl: false,
	// 		zoomControl: true,
	// 		zoomControlOptions: {
	// 			style: google.maps.ZoomControlStyle.DEFAULT
	// 		}
	// 	};

	// 	var map = new google.maps.Map(document.getElementById(args.id),mapOptions);

	// 	var marker = new google.maps.Marker({
	// 		position: venue,
	// 		map: map,
	// 		animation: google.maps.Animation.DROP,
	// 		title:location.title
	// 	});

	// }

	// if( $('.gmap').length ){
	// 	$('.gmap').each(function(){
	// 		initializeMap({ longitude: $(this).data('lng'), latitude: $(this).data('lat'), id: $(this).attr('id') });
	// 	});
	// }

	enquire.register("screen and (max-width:1025px)", {
      match : function() {
        // $('.main-header-nav').removeAttr('style');
        // $('.mobile-toggle').removeClass('active');
      },
      unmatch : function() {
      	$('.main-header-nav').removeAttr('style');
        $('.mobile-toggle').removeClass('active');
      },
  	});

	$('.mobile-toggle').click(function(){
	    $('.main-header-nav').slideToggle();
	    $(this).toggleClass('active');
	  });

})(jQuery);